<template>
  <v-main>

    <v-container
        id="users-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-bank-transfer-in"
          class="px-5 py-5"
      >

        <v-card-text v-if="claim">

          <div>
            <v-alert
                type="error"
                outlined
                v-for="(error, i) in errors"
                :key="i"
            >
              {{ error }}
            </v-alert>
          </div>

          <v-row>
            <v-btn
                small
                color="secondary"
                @click="$router.push(`/member/${claim.user.id}/claim/${claim.id}`)"
            >
              <v-icon dark>
                mdi-briefcase-edit-outline
              </v-icon>
               Edit
            </v-btn>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="3"
                class="font-weight-bold"
            >
              Member Name:
            </v-col>
            <v-col
                cols="12"
                sm="4"
            >
              {{ claim.user.names }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="3"
                class="font-weight-bold"
            >
              Dependent Name:
            </v-col>
            <v-col
                cols="12"
                sm="4"
            >
              {{ claim.dependent.names }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="6"
                sm="3"
                class="font-weight-bold"
            >
              Relationship:
            </v-col>
            <v-col
                cols="6"
                sm="3"
            >
              {{ claim.dependent.relationType.name }}
            </v-col>
            <v-col
                cols="6"
                sm="3"
                class="font-weight-bold"
            >
              Dependent Location:
            </v-col>
            <v-col
                cols="6"
                sm="3"
            >
              {{ claim.dependent.city }} - {{ claim.dependent.county }}, {{ claim.dependent.country }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="6"
                sm="3"
                class="font-weight-bold"
            >
              Cause:
            </v-col>
            <v-col
                cols="6"
                sm="3"
            >
              {{ claim.cause }}
            </v-col>
            <v-col
                cols="6"
                sm="3"
                class="font-weight-bold"
            >
              Incident Date:
            </v-col>
            <v-col
                cols="6"
                sm="3"
            >
              {{ claim.incidentDate | formatDate }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="6"
                sm="3"
                class="font-weight-bold"
            >
              Claim Status:
            </v-col>
            <v-col
                cols="6"
                sm="3"
            >
              {{ claimStatusMap[claim.status] }}
            </v-col>
            <v-col
                cols="6"
                sm="3"
                class="font-weight-bold"
            >
              Verified By:
            </v-col>
            <v-col
                cols="6"
                sm="3"
            >
              <span v-if="claim.verifiedBy">
                {{ claim.verifiedBy.names }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="6"
                sm="3"
                class="font-weight-bold"
            >
              Payment Amount:
            </v-col>
            <v-col
                cols="6"
                sm="3"
            >
              <span v-if="claim.paymentAmount">
                {{ claim.paymentAmount | formatCurrencyUSD }}
              </span>
            </v-col>
            <v-col
                cols="6"
                sm="3"
                class="font-weight-bold"
            >
              Payment Date:
            </v-col>
            <v-col
                cols="6"
                sm="3"
            >
              {{ claim.paymentDate | formatDate }}
            </v-col>
          </v-row>

        </v-card-text>


      </base-material-card>


      <base-material-card
          icon="mdi-bank-transfer-in"
          class="px-5 py-5"
          title="Replenishments"
      >

        <v-card-text v-if="claim">

          <v-simple-table>
            <template v-slot:default>
              <thead text-subtitle-2>
              <tr>
                <th class="invoice-date">Invoice Date</th>
                <th class="invoice-number">Invoice #</th>
                <th class="invoice-bill-to">Member</th>
                <th class="invoice-amount text-right">Amount</th>
                <th class="invoice-balance text-right">Balance</th>
                <th class="invoice-due-date">Due Date</th>
                <th class="invoice-status">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="replenishment in replenishments"
                  :key="replenishment.id"
              >
                <td>{{ replenishment.invoiceDate | formatDate }}</td>
                <td>
                  <router-link :to="{ name: 'view_invoice', params: {invoiceId: replenishment.id} }">
                    {{ replenishment.id }}
                  </router-link>
                </td>
                <td>
                  {{ replenishment.user.names }}
                </td>
                <td class="text-right">{{ replenishment.total | formatCurrency }}</td>
                <td class="text-right">{{ replenishment.balance | formatCurrency }}</td>
                <td>{{ replenishment.dueDate | formatDate }}</td>
                <td>{{ replenishmentStatusMap[replenishment.status] }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'

export default {
  name: 'CoreViewClaimView',

  created() {
    this.claimId = this.$route.params.claimId
    this.claimUserId = this.$route.params.memberId
    this.fetchClaim()
  },

  methods: {
    fetchClaim() {
      api
          .fetchClaim(this.claimUserId, this.claimId)
          .then(response => {
            this.claim = response.data
            this.fetchReplenishments()
          })
          .catch(err => {
            this.errors.push(api.parseResponseError(err))
          })
    },

    fetchReplenishments() {
      api
        .fetchClaimInvoices(this.claim.id)
        .then(response => {
          this.replenishments = response.data
        })
        .catch(err => {
          this.errors.push(api.parseResponseError(err))
        })
    }
  },

  data() {
    return {
      errors: [],
      claim: null,
      replenishments: [],
      claimStatusMap: constants.getClaimStatusMap(),
      replenishmentStatusMap: constants.getInvoiceStatusMap(),
    }
  }
}
</script>


<style lang="scss">
th.invoice-date, th.invoice-due-date, th.invoice-status {
  width: 10%;
}

th.invoice-number, th.invoice-amount, th.invoice-balance {
  width: 10%;
}
</style>